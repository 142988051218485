"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSupportedCollations = void 0;
var collations_generated_1 = require("./collations.generated");
function isSupported(collation, locale) {
    if (locale === void 0) { locale = 'en'; }
    try {
        return (Intl.Collator("".concat(locale, "-u-co-").concat(collation)).resolvedOptions()
            .collation === collation);
    }
    catch (_err) { }
    return false;
}
function getSupportedCollations(locale) {
    return collations_generated_1.collations.filter(function (collation) { return isSupported(collation, locale); });
}
exports.getSupportedCollations = getSupportedCollations;
