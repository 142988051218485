"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var he_1 = require("he");
/**
 * Node Class as base class for TextNode and HTMLElement.
 */
var Node = /** @class */ (function () {
    function Node(parentNode, range) {
        if (parentNode === void 0) { parentNode = null; }
        this.parentNode = parentNode;
        this.childNodes = [];
        Object.defineProperty(this, 'range', {
            enumerable: false,
            writable: true,
            configurable: true,
            value: range !== null && range !== void 0 ? range : [-1, -1]
        });
    }
    /**
     * Remove current node
     */
    Node.prototype.remove = function () {
        var _this = this;
        if (this.parentNode) {
            var children = this.parentNode.childNodes;
            this.parentNode.childNodes = children.filter(function (child) {
                return _this !== child;
            });
            this.parentNode = null;
        }
        return this;
    };
    Object.defineProperty(Node.prototype, "innerText", {
        get: function () {
            return this.rawText;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Node.prototype, "textContent", {
        get: function () {
            return (0, he_1.decode)(this.rawText);
        },
        set: function (val) {
            this.rawText = (0, he_1.encode)(val);
        },
        enumerable: false,
        configurable: true
    });
    return Node;
}());
exports.default = Node;
