"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSupportedNumberingSystems = void 0;
var numbering_systems_generated_1 = require("./numbering-systems.generated");
function isSupportedNumberingSystem(system, locale) {
    if (locale === void 0) { locale = 'en'; }
    try {
        var numberFormat = new Intl.NumberFormat("".concat(locale, "-u-nu-").concat(system));
        var options = numberFormat.resolvedOptions().numberingSystem;
        if ((options === system && system === 'latn') ||
            numberFormat.format(123) !== '123') {
            return true;
        }
    }
    catch (_err) { }
    return false;
}
function getSupportedNumberingSystems(locale) {
    return numbering_systems_generated_1.numberingSystemNames.filter(function (numberingSystemName) {
        return isSupportedNumberingSystem(numberingSystemName, locale);
    });
}
exports.getSupportedNumberingSystems = getSupportedNumberingSystems;
