"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasicFormatMatcher = void 0;
var tslib_1 = require("tslib");
var ecma402_abstract_1 = require("@formatjs/ecma402-abstract");
var utils_1 = require("./utils");
/**
 * https://tc39.es/ecma402/#sec-basicformatmatcher
 * @param options
 * @param formats
 */
function BasicFormatMatcher(options, formats) {
    var bestScore = -Infinity;
    var bestFormat = formats[0];
    (0, ecma402_abstract_1.invariant)(Array.isArray(formats), 'formats should be a list of things');
    for (var _i = 0, formats_1 = formats; _i < formats_1.length; _i++) {
        var format = formats_1[_i];
        var score = 0;
        for (var _a = 0, DATE_TIME_PROPS_1 = utils_1.DATE_TIME_PROPS; _a < DATE_TIME_PROPS_1.length; _a++) {
            var prop = DATE_TIME_PROPS_1[_a];
            var optionsProp = options[prop];
            var formatProp = format[prop];
            if (optionsProp === undefined && formatProp !== undefined) {
                score -= utils_1.additionPenalty;
            }
            else if (optionsProp !== undefined && formatProp === undefined) {
                score -= utils_1.removalPenalty;
            }
            else if (prop === 'timeZoneName') {
                if (optionsProp === 'short' || optionsProp === 'shortGeneric') {
                    if (formatProp === 'shortOffset') {
                        score -= utils_1.offsetPenalty;
                    }
                    else if (formatProp === 'longOffset') {
                        score -= utils_1.offsetPenalty + utils_1.shortMorePenalty;
                    }
                    else if (optionsProp === 'short' && formatProp === 'long') {
                        score -= utils_1.shortMorePenalty;
                    }
                    else if (optionsProp === 'shortGeneric' &&
                        formatProp === 'longGeneric') {
                        score -= utils_1.shortMorePenalty;
                    }
                    else if (optionsProp !== formatProp) {
                        score -= utils_1.removalPenalty;
                    }
                }
                else if (optionsProp === 'shortOffset' &&
                    formatProp === 'longOffset') {
                    score -= utils_1.shortMorePenalty;
                }
                else if (optionsProp === 'long' || optionsProp === 'longGeneric') {
                    if (formatProp === 'longOffset') {
                        score -= utils_1.offsetPenalty;
                    }
                    else if (formatProp === 'shortOffset') {
                        score -= utils_1.offsetPenalty + utils_1.longLessPenalty;
                    }
                    else if (optionsProp === 'long' && formatProp === 'short') {
                        score -= utils_1.longLessPenalty;
                    }
                    else if (optionsProp === 'longGeneric' &&
                        formatProp === 'shortGeneric') {
                        score -= utils_1.longLessPenalty;
                    }
                    else if (optionsProp !== formatProp) {
                        score -= utils_1.removalPenalty;
                    }
                }
                else if (optionsProp === 'longOffset' &&
                    formatProp === 'shortOffset') {
                    score -= utils_1.longLessPenalty;
                }
                else if (optionsProp !== formatProp) {
                    score -= utils_1.removalPenalty;
                }
            }
            else if (optionsProp !== formatProp) {
                var values = void 0;
                if (prop === 'fractionalSecondDigits') {
                    values = [1, 2, 3];
                }
                else {
                    values = ['2-digit', 'numeric', 'narrow', 'short', 'long'];
                }
                var optionsPropIndex = values.indexOf(optionsProp);
                var formatPropIndex = values.indexOf(formatProp);
                var delta = Math.max(-2, Math.min(formatPropIndex - optionsPropIndex, 2));
                if (delta === 2) {
                    score -= utils_1.longMorePenalty;
                }
                else if (delta === 1) {
                    score -= utils_1.shortMorePenalty;
                }
                else if (delta === -1) {
                    score -= utils_1.shortLessPenalty;
                }
                else if (delta === -2) {
                    score -= utils_1.longLessPenalty;
                }
            }
        }
        if (score > bestScore) {
            bestScore = score;
            bestFormat = format;
        }
    }
    return tslib_1.__assign({}, bestFormat);
}
exports.BasicFormatMatcher = BasicFormatMatcher;
