"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSupportedCurrencies = void 0;
var currencies_generated_1 = require("./currencies.generated");
function isSupportedCurrency(currency, locale) {
    if (locale === void 0) { locale = 'en'; }
    try {
        var numberFormat = new Intl.NumberFormat(locale, {
            style: 'currency',
            currencyDisplay: 'name',
            currency: currency,
        });
        var format = numberFormat.format(123);
        if (format.substring(0, 3) !== currency &&
            format.substring(format.length - 3) !== currency) {
            return true;
        }
    }
    catch (_err) { }
    return false;
}
function getSupportedCurrencies(locale) {
    var ATOZ = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var supportedCurrencies = [];
    for (var _i = 0, currencies_1 = currencies_generated_1.currencies; _i < currencies_1.length; _i++) {
        var currency = currencies_1[_i];
        if (currency.length === 3) {
            if (isSupportedCurrency(currency, locale)) {
                supportedCurrencies.push(currency);
            }
        }
        else if (currency.length === 5 && currency[3] === '~') {
            var start = ATOZ.indexOf(currency[2]);
            var end = ATOZ.indexOf(currency[4]);
            for (var i = start; i <= end; i++) {
                var currentCurrency = (currency.substring(0, 2) + ATOZ[i]);
                if (isSupportedCurrency(currentCurrency, locale)) {
                    supportedCurrencies.push(currentCurrency);
                }
            }
        }
    }
    return supportedCurrencies;
}
exports.getSupportedCurrencies = getSupportedCurrencies;
