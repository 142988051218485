"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calendars = void 0;
/* @generated */
// prettier-ignore
exports.calendars = {
    "001": [
        "gregorian"
    ],
    "AE": [
        "gregorian",
        "islamic-umalqura",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "AF": [
        "persian",
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "AL": [
        "gregorian",
        "islamic-civil",
        "islamic-tbla"
    ],
    "AZ": [
        "gregorian",
        "islamic-civil",
        "islamic-tbla"
    ],
    "BD": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "BH": [
        "gregorian",
        "islamic-umalqura",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "CN": [
        "gregorian",
        "chinese"
    ],
    "CX": [
        "gregorian",
        "chinese"
    ],
    "DJ": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "DZ": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "EG": [
        "gregorian",
        "coptic",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "EH": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "ER": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "ET": [
        "gregorian",
        "ethiopic"
    ],
    "HK": [
        "gregorian",
        "chinese"
    ],
    "ID": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "IL": [
        "gregorian",
        "hebrew",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "IN": [
        "gregorian",
        "indian"
    ],
    "IQ": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "IR": [
        "persian",
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "JO": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "JP": [
        "gregorian",
        "japanese"
    ],
    "KM": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "KR": [
        "gregorian",
        "dangi"
    ],
    "KW": [
        "gregorian",
        "islamic-umalqura",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "LB": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "LY": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "MA": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "MO": [
        "gregorian",
        "chinese"
    ],
    "MR": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "MV": [
        "gregorian",
        "islamic-civil",
        "islamic-tbla"
    ],
    "MY": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "NE": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "OM": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "PK": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "PS": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "QA": [
        "gregorian",
        "islamic-umalqura",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "SA": [
        "islamic-umalqura",
        "gregorian",
        "islamic",
        "islamic-rgsa"
    ],
    "SD": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "SG": [
        "gregorian",
        "chinese"
    ],
    "SY": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "TD": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "TH": [
        "buddhist",
        "gregorian"
    ],
    "TJ": [
        "gregorian",
        "islamic-civil",
        "islamic-tbla"
    ],
    "TM": [
        "gregorian",
        "islamic-civil",
        "islamic-tbla"
    ],
    "TN": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ],
    "TR": [
        "gregorian",
        "islamic-civil",
        "islamic-tbla"
    ],
    "TW": [
        "gregorian",
        "roc",
        "chinese"
    ],
    "UZ": [
        "gregorian",
        "islamic-civil",
        "islamic-tbla"
    ],
    "XK": [
        "gregorian",
        "islamic-civil",
        "islamic-tbla"
    ],
    "YE": [
        "gregorian",
        "islamic",
        "islamic-civil",
        "islamic-tbla"
    ]
};
