"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSupportedCalendars = void 0;
var calendars_generated_1 = require("./calendars.generated");
function isSupportedCalendar(item, locale) {
    if (locale === void 0) { locale = 'en'; }
    try {
        var dateTimeFormat = new Intl.DateTimeFormat("".concat(locale, "-u-ca-").concat(item));
        var options = dateTimeFormat.resolvedOptions().calendar;
        if (item !== 'gregory' || options !== 'gregory')
            return true;
    }
    catch (_err) { }
    return false;
}
function getSupportedCalendars(localePrefix) {
    return calendars_generated_1.calendars.filter(function (calendar) {
        return isSupportedCalendar(calendar, localePrefix);
    });
}
exports.getSupportedCalendars = getSupportedCalendars;
